<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Category Name"
              invalid-feedback="Category name is required."
              ref="name"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter category name"
                name="classField"
                ref="name"
                v-model.trim="myObj.category"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1 fullWidth"
              @click="Add()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-if="request == false"> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Category"
              invalid-feedback="Category is required."
              ref="catID"
            >
              <v-select
                ref="catID"
                v-model="subObj.categoryID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="items"
                :reduce="(val) => val.id"
                label="category"
                :clearable="false"
                placeholder="select category"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Subcategory Name"
              invalid-feedback="Subcategory name is required."
              ref="subname"
            >
              <b-form-input
                placeholder="Enter subcategory name"
                ref="subname"
                v-model.trim="subObj.name"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              @click="saveSub()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row align-h="end" class="text-right">
        <b-col class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1" cols="11">
          <b-dropdown
            style="margin-inline: 2px; margin-bottom: 2px"
            v-for="(d, ind) in items"
            :key="ind"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            split
            :text="d.category"
            :variant="current == d.id ? 'primary' : 'outline-primary'"
            right
            @click="LoadSubs(d.id)"
          >
            <b-dropdown-item @click="Edit(d)">Edit</b-dropdown-item>
            <b-dropdown-item @click="Delete(d)">Delete</b-dropdown-item>

            <b-dropdown-divider />
          </b-dropdown>
        </b-col>
        <b-col
          class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          style="margin-right: 8px"
          cols="*"
        >
          <b-button
            @click="AddOpen()"
            style=""
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon rounded-circle"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col xl="2" lg="3" md="4" sm="12" cols="12">
          <b-button
            @click="openSub(0)"
            style="width: 100%"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Subcategory</span>
          </b-button>
        </b-col>

        <b-col
          class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          xl="10"
          lg="9"
          md="8"
          sm="12"
          cols="12"
        >
          <b-form-group class="">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        ref="selectableTable"
        :items="filters"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="dataLoading"
        show-empty
        responsive
        hover
      >
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template #cell(category)="data">
          <b-badge style="margin-inline: 2px" variant="light-primary">
            {{ data.item.category }}
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="mr-5 text-right">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="openSub(data.item)"
            >
              <feather-icon icon="EditIcon" class="" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="DeleteSub(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
      <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="filters.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,

    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    this.LoadData();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    getColor(ind) {
      console.log("%%%%%% getting color", ind.index);
      return this.color[ind];
    },

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filters: function () {
      return this.subCategories.filter((pro) => {
        return pro.category.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,

      sidebarTitle: "Add Student Category",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "category", key: "category" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      selected: null,
      rangeDate: null,
      myObj: {
        id: 0,
        category: "",
        campusID: this.$store.state.userData.cId,
      },
      current: 0,
      subCategories: [],
      subObj: {},
      visibility2: false,
    };
  },

  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    openSub(id) {
      if (id == 0) {
        this.subObj = {
          id: 0,
          name: "",
          categoryID: this.current,
          campusID: this.$store.state.userData.cId,
        };
        this.sidebarTitle = "Add Subcategory";
        this.sidebarButton = "Save";
      } else {
        this.subObj = { ...id };
        this.sidebarTitle = "Edit Subcategory";
        this.sidebarButton = "Update";
      }

      this.visibility2 = true;
      var elem = this.$refs["subname"];
      elem.state = undefined;
    },

    async Edit(item) {
      this.myObj = { ...item };
      this.visibility = true;
      this.sidebarTitle = "Edit Student Category";
      this.sidebarButton = "Update";
      var elem = this.$refs["name"];
      elem.state = undefined;
    },

    AddOpen() {
      this.myObj = {
        id: 0,
        category: "",
        campusID: this.$store.state.userData.cId,
      };
      this.visibility = true;
      this.sidebarTitle = "Add Student Category";
      this.sidebarButton = "Save";
      var elem = this.$refs["name"];
      elem.state = undefined;
    },

    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.category == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },

    LoadSubs(id) {
      this.current = id;
    },
    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "StudentCategories?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      if (this.items.length > 0) this.current = this.items[0].id;
      this.dataLoading = false;
      // console.log("cat", this.items);
    },

    async Add() {
      this.CheckName();
      if (this.CheckName() == true) {
        // console.log(this.myObj);
        if (this.myObj.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "StudentCategories?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Student category added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadData();
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "StudentCategories/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Student Category updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          if (status) {
            this.LoadData();
            this.visibility = false;
          }
          this.request = false;
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "StudentCategories/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Student category removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },

    CheckSubName() {
      var elem = this.$refs["subname"];
      if (this.subObj.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveSub() {
      this.CheckSubName();
      if (this.CheckSubName() == true) {
        console.log(this.subObj);
        // if (this.subObj.id == 0) {
        //   //Add
        //   this.request = true;
        //   var status = await this.post({
        //     url:
        //       this.$store.state.domain +
        //       "StudentCategories?db=" +
        //       this.$store.state.userData.db,
        //     body: this.subObj,
        //     message: "Subcategory added successfully.",
        //     context: this,
        //     token: this.$store.state.userData.token,
        //   });
        // this.request = false;
        //   if (status) {
        //     this.LoadSubs(this.current);
        //     this.visibility2 = false;
        //   }
        // } else {
        //   //Edit
        //   this.request = true;
        //   var status = await this.put({
        //     url:
        //       this.$store.state.domain +
        //       "StudentCategories/" +
        //       this.subObj.id +
        //       "?db=" +
        //       this.$store.state.userData.db,
        //     body: this.subObj,
        //     message: "Subcategory updated successfully.",
        //     context: this,
        //     token: this.$store.state.userData.token,
        //   });
        //   if (status) {
        //     this.LoadSubs(this.current);
        //     this.visibility2 = false;
        //   }
        //   this.request = false;
        // }
      }
    },

    async DeleteSub(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        // var status = await this.delete({
        //   url:
        //     this.$store.state.domain +
        //     "StudentCategories/" +
        //     id +
        //     "?db=" +
        //     this.$store.state.userData.db,
        //   body: null,
        //   message: "Subcategory removed successfully.",
        //   context: this,
        //   token: this.$store.state.userData.token,
        // });
        // if (status) this.LoadSubs(this.current);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
